const A3MAuth = {
  install (app) {
    /*const {shortcodes, helpers} = options*/

    app.config.globalProperties.$hasPermission = function (codename) {
      for (let permission of this.$store.state.authStore.permissions) {
        if (permission.codename == codename) {
          return true
        }
      }
      return false
    }
  }
}

export default A3MAuth