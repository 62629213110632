import { apolloVuexSingleStoreFactory } from '../../../libs/ApolloVuexSingleStore'
import { ORGANISATION_WIDGET_QUERY, ORGANISATION_WIDGET_CREATE_MUTATION } from "./graphql";

/* eslint-disable no-unused-vars */
let store = apolloVuexSingleStoreFactory({
  settings: {
    fetchQuery: ORGANISATION_WIDGET_QUERY,
    fetchFieldName: 'organisationWidget',

    updateMutation:  ORGANISATION_WIDGET_CREATE_MUTATION,
    updateMutationName: 'updateOrganisationWidget',
    updateFieldName: 'organisationWidgetCountrySelection',
  },
  actions: {
    getIdFromUpdateVariables({ state, commit, rootState }, variables) {
      return variables.input.id
    },
  },
})
/* eslint-enable no-unused-vars */

export default store