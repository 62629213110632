import { DestinationRepository } from "@/repositories/destinationRepository"
import { MyJourneyRepository } from "@/repositories/myJourneyRepository"
import { MapRepository } from "@/repositories/mapRepository"

const RepositoriesPlugin = {
  install (app, store) {
    app.config.globalProperties.$destinationRepository = new DestinationRepository(store, app.config.globalProperties.$apollo)
    app.config.globalProperties.$myJourneyRepository = new MyJourneyRepository(store, app.config.globalProperties.$apollo)
    app.config.globalProperties.$mapRepository = new MapRepository(store, app.config.globalProperties.$apollo)
  }
}

export default RepositoriesPlugin