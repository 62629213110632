<template>
  <v-navigation-drawer color="#032545" v-if="authToken" class="app-bar" width="300">

    <v-list>
      <v-list-item class="py-4 app-bar-logo">
        <router-link :to="{name: 'index'}" class="site-name"><img src="@/assets/logo_dm_sidebar.png" class="header-logo" alt="dm logo"/></router-link>
      </v-list-item>
    </v-list>

    <v-list class="py-0 app-bar-list">
      <v-btn variant="text" class="app-bar-item" @click="homePage" :class="{ 'active-button': isActive('index') }">
        <div class="app-bar-item-icon">
          <img height="24" src="@/assets/appbar_icons/start.png" alt="home icon"/>
        </div>
        <span class="app-bar-item-text">{{ $t('main.HOME') }}</span>
      </v-btn>

      <v-divider class="divider"></v-divider>

      <v-btn variant="text" class="app-bar-item" :to="{name: 'destination-map'}" v-if="$hasPermission('a3m_core.DestinationManager')" :class="{ 'active-button': isActive('destination-map') }">
        <div class="app-bar-item-icon">
          <img height="24" src="@/assets/appbar_icons/map.svg" alt="map icon"/>
        </div>
        <span class="app-bar-item-text">{{ $t('main.RESTRICTION_MAP') }}</span>
      </v-btn>

      <v-divider class="divider" :class="{'divider-disable': !$hasPermission('a3m_core.DestinationManager')}"></v-divider>

      <div class="events-button" v-if="$hasPermission('a3m_core.DestinationManagerEvents')">
        <v-btn variant="text" class="app-bar-item" :to="{name: 'events'}" :class="{ 'active-button': isActive('events') }">
          <div class="app-bar-item-icon">
            <Svg-icon size="24" type="mdi" class="menu-icon" :path="iconEvent"></Svg-icon>
          </div>
          <span class="app-bar-item-text">{{ $t('main.GMEVENTS') }}</span>
        </v-btn>
        <v-btn variant="text" class="app-bar-item" :to="{name: 'events-map'}" :class="{ 'active-button': isActive('events-map') }">
          <div class="app-bar-item-icon">
            <img height="24" src="@/assets/appbar_icons/event-map.svg" alt="event map icon" />
          </div>
          <span class="app-bar-item-text">{{ $t('main.EVENT_MAP') }}</span>
        </v-btn>

        <v-divider class="divider"></v-divider>
      </div>

      <v-btn variant="text" class="app-bar-item" :to="{name: 'mytrip-history'}" v-if="$hasPermission('a3m_core.DestinationManagerListMyJourney')" :class="{ 'active-button': isActive('mytrip-history') }">
        <div class="app-bar-item-icon">
          <Svg-icon size="24" type="mdi" class="menu-icon" :path="iconMyTrip"></Svg-icon>
        </div>
        <span class="app-bar-item-text">{{ $t('main.MYJOURNEY_PAGES') }}</span>
      </v-btn>

      <v-divider class="divider" :class="{'divider-disable': !$hasPermission('a3m_core.DestinationManagerListMyJourney')}"></v-divider>

      <v-btn variant="text" class="app-bar-item" :to="{name: 'countries-information'}" v-if="$hasPermission('a3m_core.DestinationManagerCDB')" :class="{ 'active-button': isActive('countries-information') }">
        <div class="app-bar-item-icon">
          <Svg-icon size="24" type="mdi" class="menu-icon" :path="iconCdb"></Svg-icon>
        </div>
        <span class="app-bar-item-text">{{ $t('main.COUNTRYDATABASE') }}</span>
      </v-btn>

      <v-btn variant="text" class="app-bar-item" :to="{name: 'risk-matrix'}" v-if="$hasPermission('a3m_core.DestinationManagerCDB')" :class="{ 'active-button': isActive('risk-matrix') }">
        <div class="app-bar-item-icon">
          <v-icon size="24" class="menu-icon" color="white">apps</v-icon>
        </div>
        <span class="app-bar-item-text">{{ $t('main.RISK_MATRIX') }}</span>
      </v-btn>

      <v-divider class="divider" :class="{'divider-disable': !$hasPermission('a3m_core.DestinationManagerCDB')}"></v-divider>

      <v-btn variant="text" class="app-bar-item" :to="{name: 'news'}" :class="{ 'active-button': isActive('news') }">
        <div class="app-bar-item-icon">
          <Svg-icon size="24" type="mdi" class="menu-icon" :path="iconNews"></Svg-icon>
        </div>
        <span class="app-bar-item-text">{{ $t('main.NEWS') }}</span>
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn variant="text" class="app-bar-item" :href="helpLinkUrl" target="_blank">
        <div class="app-bar-item-icon">
          <Svg-icon size="24" type="mdi" class="menu-icon" :path="iconHelp"></Svg-icon>
        </div>
        <span class="app-bar-item-text">{{ $t('main.TUTORIALS_AND_HELP') }}</span>
      </v-btn>

      <v-btn
        variant="text"
        class="app-bar-item"
        @click="toggleCard"
        :class="{'active-button': isActive(['profile-edit', 'organisation-countries-selection', 'mytrip-filter-settings', 'widget-settings'])}">
        <div class="app-bar-item-icon">
          <Svg-icon size="24" type="mdi" class="menu-icon" :path="iconSettings"></Svg-icon>
        </div>
        <span class="app-bar-item-text">{{ $t('main.SETTINGS') }}</span>
        <ToolbarAvatar v-model:menu="isCardVisible"/>
      </v-btn>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {mapState, mapGetters, mapMutations} from 'vuex'
import ToolbarAvatar from '@/components/app/ToolbarAvatar'
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiRhombus, mdiCog, mdiNewspaperVariantMultiple, mdiFlagVariant, mdiWalletTravel, mdiHelpCircle } from '@mdi/js';


export default {
  name: 'AppBar',
  components: {
    SvgIcon,
    ToolbarAvatar
  },
  data(){
    return {
      iconEvent: mdiRhombus,
      iconSettings: mdiCog,
      iconNews: mdiNewspaperVariantMultiple,
      iconCdb: mdiFlagVariant,
      iconMyTrip: mdiWalletTravel,
      iconHelp: mdiHelpCircle,
      isCardVisible: false,
    }
  },
  computed: {
    ...mapState({
      authToken: state => state.authStore.authToken,
      user: state => state.authStore.user,
      loading: state => state.loading,
      queriesInProgress: state => state.queriesInProgress,
      language: state => state.uiStore.language,
    }),
    ...mapGetters({
      cdbUrl: 'authStore/cdbUrl'
    }),
    helpLinkUrl() {
      if (this.language == 'de') {
        return "https://www.global-monitoring.com/blog/portfolio/faq-destination-manager/"
      }

      return "https://www.global-monitoring.com/en/blog/portfolio/faq-destination-manager/"
    },
  },
  methods: {
    ...mapMutations({
      SET_AUTOCOMPLETE_FORM: 'uiStore/SET_AUTOCOMPLETE_FORM',
    }),
    toggleCard() {
      this.isCardVisible = !this.isCardVisible;
    },
    isActive(routeNames) {
      if (Array.isArray(routeNames)) {
        return routeNames.includes(this.$route.name);
      } else {
        return this.$route.name === routeNames;
      }
    },
    homePage() {
      this.SET_AUTOCOMPLETE_FORM(false)
      this.$router.push({ name: 'index' })
    },
  }
}
</script>

<style>
  .app-bar .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
  }
</style>

<style scoped>
  .app-bar {
    display: flex;
  }

  .app-bar > .v-navigation-drawer__content {
    display: flex;
  }

  .header-logo {
    height: 90px;
  }

  .app-bar-list {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .app-bar-item-text {
    color: #e1e1e1;
    font-weight: 600;
    font-size: 14px;
  }

  .app-bar-item {
    letter-spacing: normal;
    min-height: 48px;
    padding: 0 16px;
    width: 100%;
    text-transform: none;
    justify-content: flex-start;
  }

  .app-bar-item:hover {
    background-color: #002f59;
  }

  .divider {
    background-color: #e1e1e130;
    margin-inline: 60px;
    border-width: 0.5px 0 0;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .menu-icon {
    color: #e1e1e1;
  }

  .app-bar-item-icon {
    align-items: center;
    height: fit-content;
    display: flex;
    margin-block: 0 !important;
    margin-inline: 12px !important;
  }

  .divider-disable {
    display: none;
  }

  .active-button {
    background-color: #286dd4 !important;
  }

  .active-button:hover {
    background-color: #286dd4;
  }

  .app-bar-logo {
    display: flex;
    justify-content: center;
  }
</style>