import Vue from 'vue'
import { createI18n } from "vue-i18n";
import moment from 'moment'

function loadLocaleMessagesMain() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });

  return messages
}

function loadLocaleMessagesGenerated(messages) {
  const locales = require.context(
    "./generated/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );

  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = {
        ...messages[locale],
        ...locales(key)
      }
    }
  });

  return messages
}

function loadLocaleMessages() {
  let messages = loadLocaleMessagesMain()
  messages = loadLocaleMessagesGenerated(messages)

  return messages;
}

function getLocale() {
  let locale = process.env.VUE_APP_I18N_LOCALE || "de"
  let localStorageLocale = localStorage.getItem("language")

  if (localStorageLocale) {
    locale = localStorageLocale
  }

  // set the locale value in the moment date library
  moment.locale(locale)

  return locale
}

const i18n = createI18n({
  locale: getLocale(),
  messages: loadLocaleMessages(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "de",
});

export default i18n;