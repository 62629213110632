import { apolloVuexBaseFactory } from './ApolloVuexBaseStore'

/* eslint-disable no-unused-vars */
let defaultStore = {
  mutations: {
    setEntries(state, entries) { state.entries = entries },
    setErrors(state, errors) { state.errors = errors },
    setHasErrors(state, hasErrors) { state.hasErrors = hasErrors },
    setIsLoading(state, isLoading) { state.isLoading = isLoading },
    setIsDirty(state, isDirty) { state.isDirty = isDirty },
    setIsDone(state, isDone) { state.isDone = isDone },
    replaceEntry(state, entry) {
      let lookupId = entry.id.toString()
      let index = state.entries.findIndex(entry => entry.id.toString() === lookupId)

      // add as new
      if (index == -1) {
        state.entries.push(entry)
      } else {
        state.entries[index] = entry
      }
    },
    addEntry(state, entry) {
      state.entries.push(entry)
    },
    removeEntryById(state, id) {
      let lookupId = id.toString()
      let index = state.entries.findIndex(entry => entry.id.toString() === lookupId)
      state.entries.splice(index, 1)
    }
  },

  getters: {
    getById: (state, getters, rootState) => id => {
      let lookupId = id.toString()
      return state.entries.find(entry => entry.id.toString() === lookupId)
    },

    /*
    TODO
    getSorted(state, getters, rootState) {
      // embedded function, as getters don't allow parameters
      return (id) = {

      }
    },
    getFiltered(state, getters, rootState) {
      // embedded function, as getters don't allow parameters
      return (id) = {

      }
    },
    */
  },

  actions: {

    /**
     * params: {
     *   variables:    object,  default={},    Optional
     *   fetchOnly:    boolean, default=False, Optional. If True, returns the content in the promise, but does NOT update the store (including errors). 
     *                                                   Also does no LOCAL loading info
     *   force:        boolean, default=False, Optional. If True, loads data even if we already have content in the store
     *   emptyOnError: boolean, default=False, Optional. If True, the entries will be = [] on error. Without, we keep old entries
     * }
     */
    async fetchAll({ state, commit, rootState, dispatch }, params) {
      if (params === undefined) {
        params = {}
      }

      let fetchOnly = Object.prototype.hasOwnProperty.call(params, "fetchOnly") ? params['fetchOnly'] : false
      let force = Object.prototype.hasOwnProperty.call(params, "force") ? params['force'] : false
      let emptyOnError = Object.prototype.hasOwnProperty.call(params, "emptyOnError") ? params['emptyOnError'] : false
      let variables = Object.prototype.hasOwnProperty.call(params, "variables") ? params['variables'] : {}

      let hasError = false
      let errors = []
      let entries = []

      let queryServer = true
      /* check if we really need to query the server. If our local state is fine, then we
          skip that */
      if (!force && !state.isDirty && state.isDone) {
        queryServer = false
        entries = state.entries
      }

      variables = await dispatch('prepareFetchVariables', variables)

      if (queryServer) {
        if (!fetchOnly) {
          commit('setIsLoading', true)
        }
        try {
          /* the GraphQL query execution. Returns the dict
            from the parsed json result */
          let result = await dispatch('query', {
              query: state.settings.fetchAllQuery,
              variables: variables
            }, {
              root: true
            }
          )

          entries = await dispatch('extractFetchAllResultEntries', result)

          let successParam = {params: params, variables: variables, result: result, entries: entries}
          await dispatch('onSuccess', successParam)
          await dispatch('onFetchAllSuccess', successParam)

          // reset values fixed by the result
          if (!fetchOnly) {
            commit('setIsDirty', false)
            commit('setHasErrors', false)
            commit('setErrors', [])

            // set states relevant to the fetch
            commit('setIsLoading', false)
            commit('setIsDone', true)
            commit('setEntries', entries)
          }
        } catch(error) {
          await dispatch('onError', error)
          await dispatch('onFetchAllError', error)
          hasError = true
          errors.push(error)

          // reset loading state. Important: isDirty is untouched. And we are not "done"
          if (!fetchOnly) {
            commit('setIsLoading', false)

            // set error status
            commit('setHasErrors', true)
            commit('setErrors', errors)

            if (emptyOnError) {
              // when we reset Entries, we also must mark it as "not done", to trigger a reload
              commit('setEntries', [])
              commit('setIsDone', false)
            }
          }
        }
      }

      return new Promise((resolve, reject) => {
        if (hasError) {
          reject(errors)
        } else {
          resolve(entries)
        }
      })
    },

    /**
     * params {
     *   variables:     Object, required
     *   dirtyOnSuccess: Boolean, default=True, Optional. Makes isDirty=True on success
     * }
     */
    async create({ state, commit, rootState, dispatch }, params) {
      let variables = params['variables']
      let dirtyOnSuccess = Object.prototype.hasOwnProperty.call(params, "emptyOnError") ? params['emptyOnError'] : true

      let hasError = false
      let errors = []

      let entry = {}

      variables = await dispatch('prepareCreateVariables', variables)

      commit('setIsLoading', true)
      try {
        let result = await dispatch('query', {
            query: state.settings.createMutation,
            variables: variables
          }, {
            root: true
          }
        )

        entry = await dispatch('extractCreateResultEntry', result)

        let successParam = {params: params, variables: variables, result: result, entry: entry}
        await dispatch('onSuccess', successParam)
        await dispatch('onCreateSuccess', successParam)

        await dispatch('updateEntriesOnCreate', successParam)

        // reset values fixed by the result
        commit('setHasErrors', false)
        commit('setErrors', [])

        if (dirtyOnSuccess) {
          commit('setIsDirty', true)
        }

        // set states relevant to the fetch
        // isDone is not touched, as this marks if the fetch call was finished already
        commit('setIsLoading', false)
      } catch(error) {
        await dispatch('onError', error)
        await dispatch('onCreateError', error)
        hasError = true
        errors.push(error)

        // reset loading state. Important: isDirty is untouched. And we are not "done"
        commit('setIsLoading', false)

        // set error status
        commit('setHasErrors', true)
        commit('setErrors', errors)
      }

      return new Promise((resolve, reject) => {
        if (hasError) {
          reject(errors)
        } else {
          resolve(entry)
        }
      })
    },

    /**
     * params {
     *   variables: Object, required
     *   dirtyOnSuccess: Boolean, default=True, Optional. Makes isDirty=True on success
     * }
     */
    async update({ state, commit, rootState, dispatch }, params) {
      let variables = params['variables']
      let dirtyOnSuccess = Object.prototype.hasOwnProperty.call(params, "emptyOnError") ? params['emptyOnError'] : true

      let hasError = false
      let errors = []

      let entry = {}

      variables = await dispatch('prepareUpdateVariables', variables)

      commit('setIsLoading', true)
      try {
        let result = await dispatch('query', {
            query: state.settings.updateMutation,
            variables: variables
          }, {
            root: true
          }
        )

        entry = await dispatch('extractUpdateResultEntry', result)

        let successParam = {params: params, variables: variables, result: result, entry: entry}
        await dispatch('onSuccess', successParam)
        await dispatch('onUpdateSuccess', successParam)

        await dispatch('updateEntriesOnUpdate', successParam)

        // reset values fixed by the result
        commit('setHasErrors', false)
        commit('setErrors', [])

        if (dirtyOnSuccess) {
          commit('setIsDirty', true)
        }

        // set states relevant to the fetch
        // isDone is not touched, as this marks if the fetch call was finished already
        commit('setIsLoading', false)
      } catch(error) {
        await dispatch('onError', error)
        await dispatch('onUpdateError', error)
        hasError = true
        errors.push(error)

        // reset loading state. Important: isDirty is untouched. And we are not "done"
        commit('setIsLoading', false)

        // set error status
        commit('setHasErrors', true)
        commit('setErrors', errors)
      }

      return new Promise((resolve, reject) => {
        if (hasError) {
          reject(errors)
        } else {
          resolve(entry)
        }
      })
    },

    /**
     * params {
     *   id: String/Int/ID, required.
     *   dirtyOnSuccess: Boolean, default=True, Optional. Makes isDirty=True on success
     * }
     */
    async delete({ state, commit, rootState, dispatch }, params) {
      let id = params['id']
      let dirtyOnSuccess = Object.prototype.hasOwnProperty.call(params, "emptyOnError") ? params['emptyOnError'] : true

      let hasError = false
      let errors = []

      let entry = {}

      commit('setIsLoading', true)
      try {
        let result = await dispatch('query', {
            query: state.settings.deleteMutation,
            variables: {
              id: id
            },
          }, {
            root: true
          }
        )

        entry = await dispatch('extractDeleteResultEntry', result)

        let successParam = {params: params, id: id, result: result, entry: entry}
        await dispatch('onSuccess', successParam)
        await dispatch('onDeleteSuccess', successParam)

        await dispatch('updateEntriesOnDelete', successParam)

        // reset values fixed by the result
        commit('setHasErrors', false)
        commit('setErrors', [])

        if (dirtyOnSuccess) {
          commit('setIsDirty', true)
        }

        // set states relevant to the fetch
        // isDone is not touched, as this marks if the fetch call was finished already
        commit('setIsLoading', false)
      } catch(error) {
        await dispatch('onError', error)
        await dispatch('onDeleteError', error)
        hasError = true
        errors.push(error)

        // reset loading state. Important: isDirty is untouched. And we are not "done"
        commit('setIsLoading', false)

        // set error status
        commit('setHasErrors', true)
        commit('setErrors', errors)
      }

      return new Promise((resolve, reject) => {
        if (hasError) {
          reject(errors)
        } else {
          resolve(entry)
        }
      })
    },

    /* These actions can be overwritten and are used to do work in the variables before the
        query or mutation is called */
    async prepareFetchVariables({ state, commit, rootState }, variables) {
      return variables
    },
    async prepareCreateVariables({ state, commit, rootState }, variables) {
      return variables
    },
    async prepareUpdateVariables({ state, commit, rootState }, variables) {
      return variables
    },

    /* Thee actions can be overwritten if we need to extract the content in a different way,
        of if we need to process them after the fetch */
    extractFetchAllResultEntries({ state, commit, rootState }, result) {
      return result.data[state.settings.fetchAllFieldName].results
    },
    extractCreateResultEntry({ state, commit, rootState }, result) {
      return result.data[state.settings.createMutationName][state.settings.createFieldName]
    },
    extractUpdateResultEntry({ state, commit, rootState }, result) {
      return result.data[state.settings.updateMutationName][state.settings.updateFieldName]
    },
    extractDeleteResultEntry({ state, commit, rootState }, result) {
      return result.data[state.settings.deleteMutationName][state.settings.deleteFieldName]
    },

    /* On Mutations, the cache can be updated for an optimistic approach, but this must be done manually */
    updateEntriesOnCreate({ state, commit, rootState }, content) {},
    updateEntriesOnUpdate({ state, commit, rootState }, content) {},
    updateEntriesOnDelete({ state, commit, rootState }, content) {},

    /* These actions can be overwritten to react to events.
        Typical usages would be the manipulation of the returned data before it is
        put in the store */
    onSuccess({ state, commit, rootState }, content) {},
    onError({ state, commit, rootState }, params) {},

    onFetchAllSuccess({ state, commit, rootState }, content) {},
    onFetchAllError({ state, commit, rootState }, errors) {},

    onCreateSuccess({ state, commit, rootState }, content) {},
    onCreateError({ state, commit, rootState }, errors) {},

    onUpdateSuccess({ state, commit, rootState }, content) {},
    onUpdateError({ state, commit, rootState }, errors) {},

    onDeleteSuccess({ state, commit, rootState }, content) {},
    onDeleteError({ state, commit, rootState }, errors) {},
  }
}


function apolloVuexListStoreFactory(params) {
  let store = {
    namespaced: true,

    state: {
      settings: {
        fetchAllQuery: null,
        fetchAllFieldName: null,

        createMutation: null,
        createMutationName: null,
        createFieldName: null,

        updateMutation: null,
        updateMutationName: null,
        updateFieldName: null,

        deleteMutation: null,
        deleteMutationName: null,
        deleteFieldName: null,
      },

      entries: [],
      hasErrors: false,
      errors: [],
      isDone: false,
      isLoading: false,
      isDirty: false
    },
    actions: {},
    getters: {},
    mutations: {}
  }

  return apolloVuexBaseFactory(store, defaultStore, params)
}
/* eslint-enable no-unused-vars */

export { apolloVuexListStoreFactory }