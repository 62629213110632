import { BaseRepository } from './baseRepository'
import { LOOKUP_DESTINATIONS, LOOKUP_COUNTRIES, DESTINATION_DETAIL, SENDENTRYRESTRICTIONFEEDBACK_MUTATION } from './destinationRepository.graphql'

export class DestinationRepository extends BaseRepository {

  async lookupDestination(search) {
    return await this.query(
      LOOKUP_DESTINATIONS, {
        search: search
      },
      'lookupDestinations'
    )
  }

  async lookupCountry(search) {
    return await this.query(
      LOOKUP_COUNTRIES, {
        search: search
      },
      'lookupCountries'
    )
  }

  async getDestinationDetail(
      departureType,
      departureId,
      destinationsTypes,
      destinationIds,
      citizenshipIds,
      restrictionType,
      dateStart,
      dateEnd,
      transitTypes,
      transitIds,
  ) {
    return await this.query(
      DESTINATION_DETAIL, {
        departureType: departureType,
        departureId: departureId,
        destinationsTypes: destinationsTypes,
        destinationIds: destinationIds,
        citizenshipIds: citizenshipIds,
        restrictionType: restrictionType,
        dateStart: dateStart,
        dateEnd: dateEnd,
        transitTypes: transitTypes,
        transitIds: transitIds
      },
      'destinationDetail'
    )
  }

  async sendFeedback(title, text) {
    return await this.query(
      SENDENTRYRESTRICTIONFEEDBACK_MUTATION, {
        input: {
          title,
          text
        }
      },
      'sendEntryrestrictionFeedback'
    )
  }

  

}