import { BaseRepository } from './baseRepository'
import { MYJOURNEY_CREATE_MUTATION, MYJOURNEY_LIST_QUERY, UPDATE_MY_JOURNEY_NOTE_EMAIL_MUTATION } from './myJourneyRepository.graphql'

export class MyJourneyRepository extends BaseRepository {

  async createMyJourney(
      departureType, 
      departureId,
      destinations,
      citizenshipCountryIds,
      dateStart,
      dateEnd,
      restrictionType,
      language,
      email,
      showEvents,
      internalNote,
      transits
  ) {
    let input = {
      departureType,
      departureId,
      destinations,
      citizenshipCountryIds,
      dateStart,
      dateEnd,
      restrictionType,
      language,
      email,
      showEvents,
      internalNote,
      transits
    }

    return await this.query(
      MYJOURNEY_CREATE_MUTATION, {
        input: input,
      },
      'createMyjourney'
    )
  }

  async getMyJourneyList(limit, offset, search) {
    return await this.query(
      MYJOURNEY_LIST_QUERY, {
        limit,
        offset,
        search
      },
      'myjourneys'
    )
  }

  async updateMyJourneyNoteEmail(id, email, internalNote, isManuallyDisabled) {
    let input = {
      id,
      email,
      internalNote,
      isManuallyDisabled
    }
    return await this.query(
      UPDATE_MY_JOURNEY_NOTE_EMAIL_MUTATION, {
        input: input,
      },
      'updateMyjourneyEmailNote'
    )
  }

}