import Vue from "vue"
import moment from 'moment'

/* eslint-disable */
function date2str(x, y) {
  var z = {
    M: x.getMonth() + 1,
    d: x.getDate(),
    h: x.getHours(),
    m: x.getMinutes(),
    s: x.getSeconds()
  };
  y = y.replace(/(M+|d+|h+|m+|s+)/g, function (v) {
    return ((v.length > 1 ? "0" : "") + eval('z.' + v.slice(-1))).slice(-2)
  });

  return y.replace(/(y+)/g, function (v) {
    return x.getFullYear().toString().slice(-v.length)
  });
}

/* eslint-enable */

const filters = {
  i18n_dateToGerman(str) {
    if (!str) {
      return '';
    }
    let date = moment(str);
    return date.locale('de').format('L');
  },
  i18n_dateLong(str) {
    if (!str) {
      return '';
    }
    let date = moment(str);
    return date.format('LL');
  },
  i18n_datetimeToGerman(str) {
    if (!str) {
      return '';
    }
    let date = moment(str);
    return date.locale('de').format('LLL');
  },
  i18n_datetimeToGermanTime(str) {
    if (!str) {
      return '';
    }
    let date = moment(str);
    return date.locale('de').format('HH:mm');
  },
  striphtml(value) {
    const div = document.createElement('div');
    div.innerHTML = value;
    const text = div.textContent || div.innerText || '';
    return text;
  },
};

export default filters;