import gql from 'graphql-tag'

export const ORGANISATION_WIDGET_QUERY = gql`
query Widget {
  organisationWidget {
    result {
      id
      nationalityDefault {
        country {
          id
          iso2
          nameShort
        }
      }
      organisation {
        id
      }
      restrictionType
      nationalities {
        country {
          id
          iso2
          nameShort
        }
      }
      maxWidgetNationalities
    }
  }
}
`

export const ORGANISATION_WIDGET_CREATE_MUTATION = gql`
mutation UpdateOrganisationWidget($input: OrganisationWidgetCountrySelectionInputType!) {
  updateOrganisationWidget(input: $input) {
    ok
  }
}
`