import { ApolloClient, InMemoryCache } from '@apollo/client/core'
import { HttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { backendApiUrl } from './settings'

const httpLink = new HttpLink({
  // You should use an absolute URL here
  uri: backendApiUrl,
  credentials: 'include',
  /*fetchOptions: {
    mode: 'no-cors',
  },
  */
})

const contentTypeLink = setContext((_, { headers }) => {
  const authToken = localStorage.getItem("authToken")
  let language = 'de'
  if (localStorage.getItem("language")) {
    language = localStorage.getItem("language")
  }
  return {
    headers: Object.assign(
      headers || {},
      {
        //'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': authToken ? "JWT " + authToken : "no token",
        'Accept-Language': language
      }
    )
  }
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

export default new ApolloClient({
  link: contentTypeLink.concat(httpLink),
  cache: new InMemoryCache(),
  connectToDevTools: true,
  defaultOptions: defaultOptions,
  onError: ({ networkError, graphQLErrors }) => {
    console.log('graphQLErrors', graphQLErrors)
    console.log('networkError', networkError)
  }
})